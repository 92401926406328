import "./MahadevKognureParichay.css";
import ParichayImage from "../../images/mk-parichay.jpg";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

export default function Parichay() {
  return (
    <div className="parichay">
      <div
        className="content_heading"
        data-aos="fade-in"
        data-aos-duration="1000"
      >
        <h3>महादेव कोगनुरे</h3>
      </div>
      <div className="parichay_content">
        <div
          className="parichay_img"
          data-aos="fade-in"
          data-aos-duration="1000"
        >
          <img src={ParichayImage} alt="ParichayImage" />
        </div>

        <div
          className="parichay_text"
          data-aos="fade-in"
          data-aos-duration="1000"
        >
          <p>
            शुन्यातून विश्वाची निर्मिती करत प्रामाणिकपणे इमाने इतबारे समाजाची
            सेवा करत भक्ती, श्रद्धा आणि श्रमाला प्राधान्य देत अनेकांना रोजगार
            उपलब्ध करून देत मानवतेचे कार्य हेच धर्म आहे अशी वृत्ती जोपासत
            सन्माननीय महादेव कोगनुरे साहेब इतरांसाठी प्रेरणादायी ठरले आहेत. एम.
            के. फाऊंडेशनच्या वतीने अनेकांना विविधांगी मदत करत केलेले संस्थापक
            अध्यक्ष महादेव कोगनुरे साहेब. शुन्यातून विश्वाची निर्मिती करत
            प्रामाणिकपणे इमाने इतबारे समाजाची सेवा करत भक्ती, श्रद्धा आणि
            श्रमाला प्राधान्य देत अनेकांना रोजगार उपलब्ध करून देत मानवतेचे कार्य
            हेच धर्म आहे अशी वृत्ती जोपासत सन्माननीय महादेव कोगनुरे साहेब
            इतरांसाठी प्रेरणादायी ठरले आहेत. एम. के. फाऊंडेशनच्या वतीने अनेकांना
            विविधांगी मदत करत केलेले संस्थापक अध्यक्ष महादेव कोगनुरे साहेब.
            शुन्यातून विश्वाची निर्मिती करत प्रामाणिकपणे इमाने इतबारे समाजाची
            सेवा करत भक्ती, श्रद्धा आणि श्रमाला प्राधान्य देत अनेकांना रोजगार
            उपलब्ध करून देत मानवतेचे कार्य हेच धर्म आहे अशी वृत्ती जोपासत
            सन्माननीय महादेव कोगनुरे साहेब इतरांसाठी प्रेरणादायी ठरले आहेत. एम.
            के. फाऊंडेशनच्या वतीने अनेकांना विविधांगी मदत करत केलेले संस्थापक
            अध्यक्ष महादेव कोगनुरे साहेब.
          </p>
        </div>
      </div>
    </div>
  );
}
