import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyC7qAfmU65ZBeiHaLsiS9TXhbZDIqX1hDA",
  authDomain: "mahadev-kognure.firebaseapp.com",
  projectId: "mahadev-kognure",
  storageBucket: "mahadev-kognure.appspot.com",
  messagingSenderId: "425727846510",
  appId: "1:425727846510:web:3ae6276398d40ad6ef1e7f"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };
