import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase-config";
import "./ManyavaranchiMate.css";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

export default function ManyavaranchiMate() {
  const [feedbacks, setFeedbacks] = useState([]);

  useEffect(() => {
    const fetchFeedbacks = async () => {
      const feedbackCollection = collection(db, "feedbacks");
      const feedbackSnapshot = await getDocs(feedbackCollection);
      const feedbackList = feedbackSnapshot.docs.map((doc) => doc.data());
      setFeedbacks(feedbackList);
    };

    fetchFeedbacks();
  }, []);

  return (
    <div className="manyavaranchi_mate">
      <div className="page_heading">
        <h1>मान्यवरांची मतं</h1>
      </div>
      {feedbacks.map((feedback, index) => (
        <div className="feedback" key={index}>
          <h6
            className="reviewers_name"
            data-aos="fade-in"
            data-aos-duration="1000"
          >
            {feedback.reviewerName}
          </h6>
          <div className="reviewers_feedback">
            <p>{feedback.review}</p>
          </div>
        </div>
      ))}
    </div>
  );
}
