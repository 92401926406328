import { Routes, Route } from "react-router-dom";

import HomePage from "./pages/HomePage";
import SamajKaryaPage from "./pages/SamajKaryaPage";
import SamajKaryaDetailPage from "./pages/SamajKaryaDetailPage";
import MKFoundationPage from "./pages/MKFoundationPage";
import ManyavaranchiMatePage from "./pages/ManyavaranchiMatePage";
import SangrahPage from "./pages/SangrahPage";

import AdminLoginPage from "./Admin/Pages/AdminLoginPage/AdminLoginPage";
import AdminPanelPage from "./Admin/Pages/AdminPanelPage/AdminPanelPage";

import "./App.css";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/activities" element={<SamajKaryaPage />} />
        <Route path="/activity/:id" element={<SamajKaryaDetailPage />} />
        <Route path="/mkfoundation" element={<MKFoundationPage />} />
        <Route path="/feedbacks" element={<ManyavaranchiMatePage />} />
        <Route path="/gallary" element={<SangrahPage />} />

        <Route path="/admin" element={<AdminLoginPage />} />
        <Route path="/admin/panel" element={<AdminPanelPage />} />
      </Routes>
    </div>
  );
}

export default App;
