import Header from "../components/Header/Header";
import Sangrah from "../components/Sangrah/Sangrah";
import Footer from "../components/Footer/Footer";

export default function JoinUsPage() {
  return (
    <div className="sangrah_page">
      <Header />
      <Sangrah />
      <Footer />
    </div>
  );
}
