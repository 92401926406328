import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../src/firebase-config";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Sangrah.css";

AOS.init();

export default function Sangrah() {
  const [images, setImages] = useState([]);

  const fetchImages = async () => {
    const querySnapshot = await getDocs(collection(db, "images"));
    const imagesList = querySnapshot.docs.map((doc) => doc.data());
    setImages(imagesList);
  };

  useEffect(() => {
    fetchImages();
  }, []);

  return (
    <div className="sangrah">
      <div className="page_heading">
        <h1>संग्रह</h1>
      </div>

      <div className="sangrah_image_container">
        {images.map((image, index) => (
          <div key={index} className="sangrah_image" data-aos="fade-up">
            <img src={image.url} alt={image.title} />
          </div>
        ))}
      </div>
    </div>
  );
}
