import { Link } from "react-router-dom";
import "./MKFoundationInfo.css";
import FantasyFoundationImg from "../../images/logo.jpg";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

export default function MKFoundationInfo() {
  return (
    <div className="mkfoundation">
      <div
        className="content_heading"
        data-aos="fade-in"
        data-aos-duration="1000"
      >
        <h3>एम. के. फाउंडेशन</h3>
      </div>
      <div className="mkfoundation_content">
        <div
          className="mkfoundation_text"
          data-aos="fade-in"
          data-aos-duration="1000"
        >
          <p>
          शुन्यातून विश्वाची निर्मिती करत प्रामाणिकपणे इमाने इतबारे समाजाची
            सेवा करत भक्ती, श्रद्धा आणि श्रमाला प्राधान्य देत अनेकांना रोजगार
            उपलब्ध करून देत मानवतेचे कार्य हेच धर्म आहे अशी वृत्ती जोपासत
            सन्माननीय महादेव कोगनुरे साहेब इतरांसाठी प्रेरणादायी ठरले आहेत. एम.
            के. फाऊंडेशनच्या वतीने अनेकांना विविधांगी मदत करत केलेले संस्थापक
            अध्यक्ष महादेव कोगनुरे साहेब.शुन्यातून विश्वाची निर्मिती करत प्रामाणिकपणे इमाने इतबारे समाजाची
            सेवा करत भक्ती, श्रद्धा आणि श्रमाला प्राधान्य देत अनेकांना रोजगार
            उपलब्ध करून देत मानवतेचे कार्य हेच धर्म आहे अशी वृत्ती जोपासत
            सन्माननीय महादेव कोगनुरे साहेब इतरांसाठी प्रेरणादायी ठरले आहेत. एम.
            के. फाऊंडेशनच्या वतीने अनेकांना विविधांगी मदत करत केलेले संस्थापक
            अध्यक्ष महादेव कोगनुरे साहेब.शुन्यातून विश्वाची निर्मिती करत प्रामाणिकपणे इमाने इतबारे समाजाची
            सेवा करत भक्ती, श्रद्धा आणि श्रमाला प्राधान्य देत अनेकांना रोजगार
            उपलब्ध करून देत मानवतेचे कार्य हेच धर्म आहे अशी वृत्ती जोपासत
            सन्माननीय महादेव कोगनुरे साहेब इतरांसाठी प्रेरणादायी ठरले आहेत. एम.
            के. फाऊंडेशनच्या वतीने अनेकांना विविधांगी मदत करत केलेले संस्थापक
            अध्यक्ष महादेव कोगनुरे साहेब.
          </p>

          <div
            className="know_more_btn"
            data-aos="fade-in"
            data-aos-duration="1000"
          >
            <Link to="/mkfoundation">अधिक वाचा…</Link>
          </div>
        </div>

        <div
          className="mkfoundation_img mk_foundation_logo"
          data-aos="fade-in"
          data-aos-duration="1000"
        >
          <img src={FantasyFoundationImg} alt="FantasyFoundationImg" />
        </div>
      </div>
    </div>
  );
}
