import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase-config";
import { Link } from "react-router-dom";
import "./SamajLKarye.css";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

export default function SamajKarye() {
  const [activities, setActivities] = useState([]);

  const fetchActivities = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "activities"));
      const activitiesList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setActivities(activitiesList);
    } catch (error) {
      console.error("Error fetching activities: ", error);
    }
  };

  useEffect(() => {
    fetchActivities();
  }, []);

  return (
    <div className="samaj_karye">
      <div className="page_heading">
        <h1>समाजकार्य</h1>
      </div>

      {activities.map((activity) => (
        <div className="activity" key={activity.id}>
          <div
            className="activity_image"
            data-aos="fade-in"
            data-aos-duration="1000"
          >
            <img src={activity.image} alt={activity.title} />
          </div>

          <div className="activity_content">
            <h1 data-aos="fade-in" data-aos-duration="1000">
              {activity.title}
            </h1>

            <p data-aos="fade-in" data-aos-duration="2000">
              {activity.text}
            </p>

            <div
              className="know_more_button"
              data-aos="fade-in"
              data-aos-duration="1000"
            >
              <Link to={`/activity/${activity.id}`}>अधिक वाचा…</Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
