import Header from "../components/Header/Header";
import HeroCarousel from "../components/HeroCarousel/HeroCarousel";
import MahadevKognureParichay from "../components/MahadevKognureParichay/MahadevKognureParichay";
import MKFoundationInfo from "../components/MKFoundationInfo/MKFoundationInfo";
import Footer from "../components/Footer/Footer";

export default function HomePage() {
  return (
    <div className="home_page">
      <Header />
      <HeroCarousel />
      <MahadevKognureParichay />
      <MKFoundationInfo />
      <Footer />
    </div>
  );
}
