import React, { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { db, storage } from "../../../firebase-config";
import {
  ChakraProvider,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import "./AdminPanelPage.css";

export default function AdminPanelPage() {
  const [section, setSection] = useState("feedbacks");
  const [feedbacks, setFeedbacks] = useState([]);
  const [activities, setActivities] = useState([]);
  const [reviewerName, setReviewerName] = useState("");
  const [review, setReview] = useState("");
  const [activityTitle, setActivityTitle] = useState("");
  const [activityText, setActivityText] = useState("");
  const [activityDate, setActivityDate] = useState("");
  const [activityImage, setActivityImage] = useState(null);
  const [editId, setEditId] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [volunteers, setVolunteers] = useState([]);
  const [selectedVolunteer, setSelectedVolunteer] = useState(null);

  const [images, setImages] = useState([]);
  const [imageTitle, setImageTitle] = useState("");
  const [imageFile, setImageFile] = useState(null);

  const fetchFeedbacks = async () => {
    const querySnapshot = await getDocs(collection(db, "feedbacks"));
    const feedbacksList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setFeedbacks(feedbacksList);
  };

  const fetchActivities = async () => {
    const querySnapshot = await getDocs(collection(db, "activities"));
    const activitiesList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setActivities(activitiesList);
  };

  useEffect(() => {
    fetchFeedbacks();
    fetchActivities();
    fetchVolunteers();
    fetchImages();
  }, []);

  const fetchVolunteers = async () => {
    const querySnapshot = await getDocs(collection(db, "volunteers"));
    const volunteersList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setVolunteers(volunteersList);
    console.log(volunteers);
  };

  const handleDeleteVolunteer = async (id) => {
    const volunteerDoc = doc(db, "volunteers", id);
    await deleteDoc(volunteerDoc);
    alert("Volunteer deleted successfully!");
    fetchVolunteers();
  };

  const handleSubmitFeedback = async (e) => {
    e.preventDefault();

    if (editId) {
      const feedbackDoc = doc(db, "feedbacks", editId);
      await updateDoc(feedbackDoc, { reviewerName, review });
      alert("Feedback updated successfully!");
      setEditId(null);
    } else {
      await addDoc(collection(db, "feedbacks"), { reviewerName, review });
      alert("Feedback added successfully!");
    }

    setReviewerName("");
    setReview("");
    fetchFeedbacks();
  };

  const handleSubmitActivity = async (e) => {
    e.preventDefault();

    if (!activityImage) {
      alert("Please select an image.");
      return;
    }

    let imageUrl = "";
    try {
      const imageRef = ref(storage, `activities/${activityImage.name}`);
      await uploadBytes(imageRef, activityImage);
      imageUrl = await getDownloadURL(imageRef);
    } catch (error) {
      console.error("Error uploading image: ", error);
      alert("Error uploading image. Please try again.");
      return;
    }

    try {
      if (editId) {
        const activityDoc = doc(db, "activities", editId);
        await updateDoc(activityDoc, {
          title: activityTitle,
          date: activityDate,
          text: activityText,
          image: imageUrl,
        });
        alert("Activity updated successfully!");
        setEditId(null);
      } else {
        await addDoc(collection(db, "activities"), {
          title: activityTitle,
          date: activityDate,
          text: activityText,
          image: imageUrl,
        });
        alert("Activity added successfully!");
      }
    } catch (error) {
      console.error("Error submitting activity: ", error);
      alert("Error submitting activity. Please try again.");
    }

    setActivityTitle("");
    setActivityDate("");
    setActivityText("");
    setActivityImage(null);
    fetchActivities();
  };

  const handleEditFeedback = (feedback) => {
    setReviewerName(feedback.reviewerName);
    setReview(feedback.review);
    setEditId(feedback.id);
  };

  const handleEditActivity = (activity) => {
    setActivityTitle(activity.title);
    setActivityText(activity.text);
    setActivityDate(activity.date);
    setActivityImage(null);
    setEditId(activity.id);
  };

  const handleDeleteFeedback = async (id) => {
    const feedbackDoc = doc(db, "feedbacks", id);
    await deleteDoc(feedbackDoc);
    alert("Feedback deleted successfully!");
    fetchFeedbacks();
  };

  const handleDeleteActivity = async (id) => {
    const activityDoc = doc(db, "activities", id);
    await deleteDoc(activityDoc);
    alert("Activity deleted successfully!");
    fetchActivities();
  };

  const handleViewFeedback = (feedback) => {
    setSelectedFeedback(feedback);
    onOpen();
  };

  const handleViewActivity = (activity) => {
    setSelectedActivity(activity);
    onOpen();
  };

  const handleViewVolunteer = (volunteer) => {
    setSelectedVolunteer(volunteer);
    onOpen();
  };

  const handleClearFeedback = () => {
    setReviewerName("");
    setReview("");
  };

  const handleClearActivity = () => {
    setActivityTitle("");
    setActivityText("");
    setActivityDate("");
    setActivityImage(null);
  };

  const handleCancel = () => {
    setReviewerName("");
    setReview("");
    setActivityTitle("");
    setActivityText("");
    setActivityDate("");
    setActivityImage(null);
    setEditId(null);
  };

  // Fetching images from Firestore
  const fetchImages = async () => {
    const querySnapshot = await getDocs(collection(db, "images"));
    const imagesList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setImages(imagesList);
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const handleImageSubmit = async (e) => {
    e.preventDefault();
    if (!imageFile) {
      alert("Please select an image file.");
      return;
    }

    let imageUrl = "";
    try {
      const imageRef = ref(storage, `sangrah/${imageFile.name}`);
      await uploadBytes(imageRef, imageFile);
      imageUrl = await getDownloadURL(imageRef);
    } catch (error) {
      console.error("Error uploading image: ", error);
      alert("Error uploading image. Please try again.");
      return;
    }

    try {
      if (editId) {
        const imageDoc = doc(db, "images", editId);
        await updateDoc(imageDoc, {
          title: imageTitle,
          url: imageUrl,
        });
        alert("Image updated successfully!");
        setEditId(null);
      } else {
        await addDoc(collection(db, "images"), {
          title: imageTitle,
          url: imageUrl,
        });
        alert("Image added successfully!");
      }
    } catch (error) {
      console.error("Error submitting image: ", error);
      alert("Error submitting image. Please try again.");
    }

    setImageTitle("");
    setImageFile(null);
    fetchImages();
  };

  const handleDeleteImage = async (id, imageUrl) => {
    const imageDoc = doc(db, "images", id);
    const imageRef = ref(storage, imageUrl);
    try {
      await deleteDoc(imageDoc);
      await deleteObject(imageRef);
      alert("Image deleted successfully!");
      fetchImages();
    } catch (error) {
      console.error("Error deleting image: ", error);
      alert("Error deleting image. Please try again.");
    }
  };

  const handleEditImage = (image) => {
    setImageTitle(image.title);
    setEditId(image.id);
  };

  const handleClearImage = () => {
    setImageTitle("");
    setImageFile(null);
    setEditId(null);
  };

  return (
    <ChakraProvider>
      <div className="admin_panel_page">
        <div className="admin_panel_page__header">
          <br />
          <h1>Mahadev Kognure - Admin Panel</h1>
          <br />
          <div className="menu">
            <button onClick={() => setSection("feedbacks")}>Feedbacks</button>
            <button onClick={() => setSection("activities")}>Activities</button>
            <button onClick={() => setSection("volunteers")}>Volunteers</button>
            <button onClick={() => setSection("images")}>Images</button>
            <button onClick={() => (window.location = "/admin")}>Logout</button>
          </div>
          <br />
        </div>

        <div className="admin_panel_page__content">
          {section === "feedbacks" && (
            <>
              <div className="admin_panel_page__form">
                <h2>{editId ? "Edit Feedback" : "Add Feedback"}</h2>
                <form onSubmit={handleSubmitFeedback}>
                  <div className="form_group">
                    <label htmlFor="reviewerName">Reviewer Name:</label>
                    <input
                      type="text"
                      id="reviewerName"
                      value={reviewerName}
                      onChange={(e) => setReviewerName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form_group">
                    <label htmlFor="review">Review:</label>
                    <textarea
                      id="review"
                      value={review}
                      rows={6}
                      onChange={(e) => setReview(e.target.value)}
                      required
                    ></textarea>
                  </div>
                  <button type="submit">{editId ? "Update" : "Submit"}</button>
                  {editId ? (
                    <button type="button" onClick={handleCancel}>
                      Cancel
                    </button>
                  ) : (
                    <button type="button" onClick={handleClearFeedback}>
                      Clear
                    </button>
                  )}
                </form>
              </div>

              <div className="admin_panel_page__table">
                <h2>Manage Feedbacks</h2>
                <table>
                  <thead>
                    <tr>
                      <th>Reviewer Name</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {feedbacks.map((feedback) => (
                      <tr key={feedback.id}>
                        <td>{feedback.reviewerName}</td>
                        <td>
                          <button onClick={() => handleEditFeedback(feedback)}>
                            Edit
                          </button>
                          <button
                            onClick={() => handleDeleteFeedback(feedback.id)}
                          >
                            Delete
                          </button>
                          <button onClick={() => handleViewFeedback(feedback)}>
                            View
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}

          {section === "activities" && (
            <>
              <div className="admin_panel_page__form">
                <h2>{editId ? "Edit Activity" : "Add Activity"}</h2>
                <form onSubmit={handleSubmitActivity}>
                  <div className="form_group">
                    <label htmlFor="activityTitle">Activity Title:</label>
                    <input
                      type="text"
                      id="activityTitle"
                      value={activityTitle}
                      onChange={(e) => setActivityTitle(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form_group">
                    <label htmlFor="activityText">Activity Text:</label>
                    <textarea
                      id="activityText"
                      value={activityText}
                      rows={6}
                      onChange={(e) => setActivityText(e.target.value)}
                      required
                    ></textarea>
                  </div>
                  <div className="form_group">
                    <label htmlFor="activityDate">Activity Date:</label>
                    <input
                      type="date"
                      id="activityDate"
                      value={activityDate}
                      onChange={(e) => setActivityDate(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form_group">
                    <label htmlFor="activityImage">Activity Image:</label>
                    <input
                      type="file"
                      id="activityImage"
                      onChange={(e) => setActivityImage(e.target.files[0])}
                    />
                  </div>
                  <button type="submit">{editId ? "Update" : "Submit"}</button>
                  {editId ? (
                    <button type="button" onClick={handleCancel}>
                      Cancel
                    </button>
                  ) : (
                    <button type="button" onClick={handleClearActivity}>
                      Clear
                    </button>
                  )}
                </form>
              </div>
              <div className="admin_panel_page__table">
                <h2>Manage Activities</h2>
                <table>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activities.map((activity) => (
                      <tr key={activity.id}>
                        <td>{activity.title}</td>
                        <td>
                          <button onClick={() => handleEditActivity(activity)}>
                            Edit
                          </button>
                          <button
                            onClick={() => handleDeleteActivity(activity.id)}
                          >
                            Delete
                          </button>
                          <button onClick={() => handleViewActivity(activity)}>
                            View
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}

          {section === "volunteers" && (
            <>
              <div className="admin_panel_page__table">
                <h2>Manage Volunteers</h2>
                <table>
                  <thead>
                    <tr>
                      <th>Full Name</th>
                      <th>Mobile</th>
                      <th>Email</th>
                      <th>Village</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {volunteers.map((volunteer) => (
                      <tr key={volunteer.id}>
                        <td>{volunteer.fullname}</td>
                        <td>{volunteer.mobile}</td>
                        <td>{volunteer.email}</td>
                        <td>{volunteer.village}</td>
                        <td>
                          {/* <button
                            onClick={() => handleViewVolunteer(volunteer)}
                          >
                            View
                          </button> */}
                          <button
                            onClick={() => handleDeleteVolunteer(volunteer.id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {selectedFeedback ? "Feedback" : "Activity"} Details
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {selectedFeedback && (
                <>
                  <p>
                    <strong>Reviewer Name:</strong>{" "}
                    {selectedFeedback.reviewerName}
                  </p>
                  <p>
                    <strong>Review:</strong> {selectedFeedback.review}
                  </p>
                </>
              )}
              {selectedActivity && (
                <>
                  <p>
                    <strong>Title:</strong> {selectedActivity.title}
                  </p>
                  <p>
                    <strong>Text:</strong> {selectedActivity.text}
                  </p>
                  <p>
                    <strong>Date:</strong> {selectedActivity.date}
                  </p>
                  {selectedActivity.image && (
                    <img src={selectedActivity.image} alt="Activity" />
                  )}
                </>
              )}

              <div>
                {section === "images" && (
                  <>
                    <div className="admin_panel_page__form">
                      <h2>{editId ? "Edit Image" : "Add Image"}</h2>
                      <form onSubmit={handleImageSubmit}>
                        <div className="form_group">
                          <label htmlFor="imageTitle">Image Title:</label>
                          <input
                            type="text"
                            id="imageTitle"
                            value={imageTitle}
                            onChange={(e) => setImageTitle(e.target.value)}
                            required
                          />
                        </div>
                        <div className="form_group">
                          <label htmlFor="imageFile">Image File:</label>
                          <input
                            type="file"
                            id="imageFile"
                            onChange={(e) => setImageFile(e.target.files[0])}
                            required
                          />
                        </div>
                        <button type="submit">
                          {editId ? "Update" : "Submit"}
                        </button>
                        {editId ? (
                          <button type="button" onClick={handleClearImage}>
                            Clear
                          </button>
                        ) : (
                          <button type="button" onClick={handleClearImage}>
                            Clear
                          </button>
                        )}
                      </form>
                    </div>

                    <div className="admin_panel_page__table">
                      <h2>Manage Images</h2>
                      <table>
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {images.map((image) => (
                            <tr key={image.id}>
                              <td>{image.title}</td>
                              <td>
                                <button onClick={() => handleEditImage(image)}>
                                  Edit
                                </button>
                                <button
                                  onClick={() =>
                                    handleDeleteImage(image.id, image.url)
                                  }
                                >
                                  Delete
                                </button>
                                <button
                                  onClick={() =>
                                    window.open(image.url, "_blank")
                                  }
                                >
                                  View
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <div className="admin_panel_page__content">
          {section === "images" && (
            <>
              <div className="admin_panel_page__form">
                <h2>{editId ? "Edit Image" : "Add Image"}</h2>
                <form onSubmit={handleImageSubmit}>
                  <div className="form_group">
                    <label htmlFor="imageTitle">Image Title:</label>
                    <input
                      type="text"
                      id="imageTitle"
                      value={imageTitle}
                      onChange={(e) => setImageTitle(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form_group">
                    <label htmlFor="imageFile">Image File:</label>
                    <input
                      type="file"
                      id="imageFile"
                      onChange={(e) => setImageFile(e.target.files[0])}
                      required
                    />
                  </div>
                  <button type="submit">
                    {editId ? "Update" : "Submit"}
                  </button>
                  <button type="button" onClick={handleClearImage}>
                    Clear
                  </button>
                </form>
              </div>

              <div className="admin_panel_page__table">
                <h2>Manage Images</h2>
                <table>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {images.map((image) => (
                      <tr key={image.id}>
                        <td>{image.title}</td>
                        <td>
                          <button onClick={() => handleEditImage(image)}>
                            Edit
                          </button>
                          <button
                            onClick={() => handleDeleteImage(image.id, image.url)}
                          >
                            Delete
                          </button>
                          <button
                            onClick={() => window.open(image.url, "_blank")}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Image Details</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {/* Modal content here */}
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    </ChakraProvider>
  );
}
