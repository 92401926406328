import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase-config";
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"

function ContactForm() {
  const [fullname, setFullname] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [village, setVillage] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "volunteers"), {
        fullname,
        mobile,
        email,
        village,
        message,
      });
      alert("Form submitted successfully!");
      // Clear the form
      setFullname("");
      setMobile("");
      setEmail("");
      setVillage("");
      setMessage("");
    } catch (error) {
      console.error("Error submitting form: ", error);
      alert("Error submitting form. Please try again.");
    }
  };

  return (
    <div className="form_container">
      <h2 style={{ marginTop: "2rem" }}>एम. के. फाउंडेशनमध्ये सामील व्हा</h2>
      <form onSubmit={handleSubmit}>
        <div className="form_group">
          <label htmlFor="fullname">पूर्ण नाव</label>
          <input
            type="text"
            id="fullname"
            name="fullname"
            value={fullname}
            onChange={(e) => setFullname(e.target.value)}
            required
          />
        </div>
        <div className="form_group">
          <label htmlFor="mobile">मोबाइल</label>
          <input
            type="tel"
            id="mobile"
            name="mobile"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            required
          />
        </div>
        <div className="form_group">
          <label htmlFor="email">ईमेल</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form_group">
          <label htmlFor="village">गाव</label>
          <input
            type="text"
            id="village"
            name="village"
            value={village}
            onChange={(e) => setVillage(e.target.value)}
            required
          />
        </div>
        <div className="form_group">
          <label htmlFor="message">संदेश</label>
          <textarea
            id="message"
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
        </div>
        <button type="submit" className="form_submit_btn">
          पाठवा
        </button>
      </form>
    </div>
  );
}

export default function MKFoundationPage() {
  return (
    <div className="contact_page">
      <Header />
      <ContactForm />
      <Footer />
    </div>
  );
}
