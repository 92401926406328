import { Link } from "react-router-dom";
import "./Footer.css";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="pre_footer">
        <div className="quick_links">
          <h4>आमचे उपक्रम</h4>
          <p>
            <a href="/activities">सामाजिक उपक्रम</a>
          </p>
          <p>
            <a href="/activities">ग्रामविकास</a>
          </p>
          <p>
            <a href="/activities">शाळा सुधारणा</a>
          </p>
          <p>
            <a href="/activities">शेतकरी मदत</a>
          </p>
          <p>
            <a href="/activities">इतर उपक्रम</a>
          </p>
        </div>

        <div className="high_school">
          <h4>संपर्क</h4>
          <p>
            <i className="ri-map-pin-2-fill"></i> पणाश बिल्डिंगसमोर, शनया हॉटेल बाजूला, दुसरा मजला, सोलापूर-४१३००६,
            महाराष्ट्र.
          </p>
          <p>
            <a href="mailto:mkfoundation@gmail.com">
              <i className="ri-mail-fill"></i>
              mkfoundation@gmail.com
            </a>
          </p>
          <p>
            <i className="ri-phone-fill"></i> +91 8888536063
          </p>
          <p>
            <i className="ri-phone-fill"></i> +91 7030235020
          </p>
        </div>

        <div className="contact">
          <h4>सामाजिक माध्यम</h4>
          <p>
            <a href="https://www.facebook.com/@MKFoundationsol/">
            <i class="fa-brands fa-facebook"></i>
              Facebook
            </a>
          </p>
          <p>
            <a href="https://www.instagram.com/mahadeo_koganure/">
            <i class="fa-brands fa-instagram"></i>
              Instagram
            </a>
          </p>
        </div>

        <div className="quick_links">
          <h4>त्वरित दुवे</h4>
          <p>
            <Link to="/">मुख्य पृष्ठ</Link>
          </p>
          <p>
            <Link to="/about">आमच्याबद्दल</Link>
          </p>
          <p>
            <Link to="/activities">आमचे उपक्रम</Link>
          </p>
          <p>
            <Link to="/career">आमच्या सोबत सामील व्हा</Link>
          </p>
          <p>
            <Link to="/contact">संपर्क साधा</Link>
          </p>
        </div>
      </div>

      <div className="main_footer">
        <p>
          <span id="currentYear"></span>
          सर्व हक्क राखीव &#169; 2024 |{" "}
          <Link to="/"> एम. के. फाउंडेशन, सोलापूर </Link> | डिझाइन केलेले{" "}
          <Link to="https://vishwalatarati.in/" target="_blank">
            विश्वालतारती डिजिटल सोल्युशन्स प्रा. लि., सोलापूर
          </Link>
        </p>
      </div>
    </footer>
  );
}
