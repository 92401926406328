import Header from "../components/Header/Header";
import ManyavaranchiMate from "../components/ManyavaranchiMate/ManyavaranchiMate";
import Footer from "../components/Footer/Footer";

export default function ManyavaranchiMatePage() {
  return (
    <div className="Manyavaranchi_mate_page">
      <Header />
      <ManyavaranchiMate />
      <Footer />
    </div>
  );
}
