import { Link } from "react-router-dom";
import "./SamajkaryaDetails.css";

export default function SamajkaryDetails({ title, date, image, text }) {
    return (
      <div className="details_container">
        <div className="details_content">
          <div className="page_heading">
            <h1>{title}</h1>
          </div>

          <div
            className="details_image"
            data-aos="fade-in"
            data-aos-duration="1000"
          >
            <img src={image} alt={title} />
          </div>

          <div
            className="details_text"
            data-aos="fade-in"
            data-aos-duration="2000"
          >
            <p>
              <span>{title}</span>  | {date}
            </p>
            <p>{text}</p>
          </div>

          <div className="back_btn" data-aos="fade-in" data-aos-duration="1000">
            <Link to="/activities"><i class="fa-solid fa-circle-left"></i> समाजकार्य</Link>
          </div>
        </div>
      </div>
    );
  }