import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./Header.css";

import Logo from "../../images/MK-LOGO.png";

export default function Header() {
  const [isActive, setIsActive] = useState(false);

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  return (
    <div>
      <div className={`header ${isActive ? "active" : ""}`}>
        <div className="logo">
          <Link to="/">
            <img src={Logo} alt="Logo" />
          </Link>
        </div>

        <div className="navbar">
          <ul className="nav_links">
            <li className="nav_link">
              <Link to="/">मुख्य पृष्ठ</Link>
            </li>

            <li className="nav_link">
              <Link to="/activities">समाजकार्य</Link>
            </li>

            <li className="nav_link">
              <Link to="/mkfoundation">एम. के. फाउंडेशन</Link>
            </li>

            <li className="nav_link">
              <Link to="/feedbacks">मान्यवरांची मतं</Link>
            </li>

            <li className="nav_link">
              <Link to="/gallary">संग्रह</Link>
            </li>
          </ul>
        </div>

        <div className="toggle" onClick={handleToggle}>
          <div className="menu_icon">
            <span className="top"></span>
            <span className="middle"></span>
            <span className="end"></span>
          </div>
        </div>
      </div>
    </div>
  );
}