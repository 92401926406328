import React from "react";

import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import "./HeroCarousel.css"

import Slide1 from "../../images/Slider1.jpg";
import Slide2 from "../../images/Slider2.jpg";
import Slide3 from "../../images/Slider3.jpg";
import Slide4 from "../../images/Slider4.jpg";
import Slide5 from "../../images/Slider5.jpg";

export default function HeroCarousel() {
  return (
    <Carousel fade>
      <Carousel.Item interval={2500}>
        <img className="d-block w-100 hero" src={Slide1} alt="First Slide" />
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img className="d-block w-100 hero" src={Slide2} alt="Second Slide" />
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img className="d-block w-100 hero" src={Slide3} alt="Third Slide" />
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img className="d-block w-100 hero" src={Slide4} alt="Second Slide" />
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img className="d-block w-100 hero" src={Slide5} alt="Third Slide" />
      </Carousel.Item>
    </Carousel>
  );
}
