import React, { useState } from "react";
import {
  ChakraProvider,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import "./AdminLoginPage.css";

export default function AdminLoginPage() {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginFailed, setLoginFailed] = useState(false);

  const handleClick = () => setShow(!show);

  function handleLogin() {
    if (email === "admin@gmail.com" && password === "123456") {
      window.location.href = "/admin/panel";
    } else {
      setLoginFailed(true);
      setTimeout(() => {
        setLoginFailed(false);
      }, 3000);
    }
  }

  return (
    <ChakraProvider>
      <div className="admin_login_page">
        <div className="admin_login_form">
          <Heading className="form_heading">Admin Login</Heading>

          <Input
            className="email_input"
            placeholder="Enter your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <InputGroup className="password_input" size="md">
            <Input
              pr="4.5rem"
              type={show ? "text" : "password"}
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={handleClick}>
                {show ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>

          <Button
            className="login_button"
            colorScheme="blue"
            onClick={handleLogin}
          >
            Login
          </Button>

          {loginFailed && (
            <Alert className="login_failed_alert" status="error">
              <AlertIcon />
              <AlertTitle>Login Failed</AlertTitle>
              <AlertDescription>Invalid Email or Password.</AlertDescription>
            </Alert>
          )}
        </div>
      </div>
    </ChakraProvider>
  );
}
