import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase-config";

import Header from "../components/Header/Header";
import SamajkaryDetails from "../components/SamajkaryDetails/SamajkaryDetails";
import Footer from "../components/Footer/Footer";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

export default function SamajkaryDetailsPage() {
  const { id } = useParams();
  const [activity, setActivity] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchActivity = async () => {
      try {
        const docRef = doc(db, "activities", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const activityData = docSnap.data();
          const formattedDate = new Date(activityData.date).toLocaleDateString(
            "en-GB"
          );
          setActivity({ ...activityData, date: formattedDate });
        } else {
          setError("No such document!");
        }
      } catch (error) {
        setError("Error fetching document: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchActivity();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!activity) {
    return <div>No activity found</div>;
  }

  return (
    <div className="samaj_karyadetails_page">
      <Header />
      <SamajkaryDetails
        title={activity.title}
        date={activity.date}
        image={activity.image}
        text={activity.text}
      />
      <Footer />
    </div>
  );
}
