import Header from "../components/Header/Header";
import SamajKarya from "../components/SamajKarye/SamajKarye";
import Footer from "../components/Footer/Footer";

export default function SamajKaryaPage() {
  return (
    <div className="samaj_karya_page">
      <Header />
      <SamajKarya />
      <Footer />
    </div>
  );
}
